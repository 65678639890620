/* line 6, src/Pandect.Web/Pandect.Web/Content/sass/components/_jumbotron.scss */
.jumbotron {
  background-color: #f4f4f4; }
  @media (min-width: 768px) {
    /* line 6, src/Pandect.Web/Pandect.Web/Content/sass/components/_jumbotron.scss */
    .jumbotron {
      position: relative;
      max-height: 500px;
      overflow: hidden; } }
  @media (min-width: 768px) {
    /* line 15, src/Pandect.Web/Pandect.Web/Content/sass/components/_jumbotron.scss */
    .jumbotron .jumbotron-content-container {
      position: absolute;
      top: 0;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      border-top: none; } }
  /* line 24, src/Pandect.Web/Pandect.Web/Content/sass/components/_jumbotron.scss */
  .jumbotron .jumbotron-content-container .jumbotron-content {
    display: inline-block; }
    @media (min-width: 768px) {
      /* line 24, src/Pandect.Web/Pandect.Web/Content/sass/components/_jumbotron.scss */
      .jumbotron .jumbotron-content-container .jumbotron-content {
        max-width: 55%; } }
    /* line 31, src/Pandect.Web/Pandect.Web/Content/sass/components/_jumbotron.scss */
    .jumbotron .jumbotron-content-container .jumbotron-content h1 {
      font-family: "Ubuntu Light";
      font-size: 1.5rem;
      line-height: 2rem;
      color: #2e2e2e; }
      @media (min-width: 768px) {
        /* line 31, src/Pandect.Web/Pandect.Web/Content/sass/components/_jumbotron.scss */
        .jumbotron .jumbotron-content-container .jumbotron-content h1 {
          font-size: 2rem;
          line-height: 2.5rem; } }
      @media (min-width: 992px) {
        /* line 31, src/Pandect.Web/Pandect.Web/Content/sass/components/_jumbotron.scss */
        .jumbotron .jumbotron-content-container .jumbotron-content h1 {
          font-size: 2.5rem;
          line-height: 3.5rem; } }
      @media (min-width: 1200px) {
        /* line 31, src/Pandect.Web/Pandect.Web/Content/sass/components/_jumbotron.scss */
        .jumbotron .jumbotron-content-container .jumbotron-content h1 {
          font-size: 2.8rem;
          line-height: 3.75rem; } }
    /* line 53, src/Pandect.Web/Pandect.Web/Content/sass/components/_jumbotron.scss */
    .jumbotron .jumbotron-content-container .jumbotron-content .link-border-arrow {
      font-size: 1.75rem;
      line-height: 2.25rem;
      color: #1f477a; }
      @media (min-width: 768px) {
        /* line 53, src/Pandect.Web/Pandect.Web/Content/sass/components/_jumbotron.scss */
        .jumbotron .jumbotron-content-container .jumbotron-content .link-border-arrow {
          max-width: 80%; } }
      @media (min-width: 992px) {
        /* line 53, src/Pandect.Web/Pandect.Web/Content/sass/components/_jumbotron.scss */
        .jumbotron .jumbotron-content-container .jumbotron-content .link-border-arrow {
          max-width: 70%;
          margin-bottom: 0.5rem !important; } }
      @media (min-width: 1200px) {
        /* line 53, src/Pandect.Web/Pandect.Web/Content/sass/components/_jumbotron.scss */
        .jumbotron .jumbotron-content-container .jumbotron-content .link-border-arrow {
          font-size: 2.8rem;
          line-height: 3.75rem; } }
      /* line 72, src/Pandect.Web/Pandect.Web/Content/sass/components/_jumbotron.scss */
      .jumbotron .jumbotron-content-container .jumbotron-content .link-border-arrow::after {
        font-size: .85rem; }
        @media (min-width: 1200px) {
          /* line 72, src/Pandect.Web/Pandect.Web/Content/sass/components/_jumbotron.scss */
          .jumbotron .jumbotron-content-container .jumbotron-content .link-border-arrow::after {
            bottom: 1rem;
            font-size: 1rem;
            line-height: 1rem; } }
  /* line 85, src/Pandect.Web/Pandect.Web/Content/sass/components/_jumbotron.scss */
  .jumbotron .slick-dots {
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: auto;
    padding: 0 0 0 1rem;
    list-style-type: none;
    text-align: left; }
    @media (min-width: 992px) {
      /* line 85, src/Pandect.Web/Pandect.Web/Content/sass/components/_jumbotron.scss */
      .jumbotron .slick-dots {
        margin-top: 1rem; } }
    @media (min-width: 1200px) {
      /* line 85, src/Pandect.Web/Pandect.Web/Content/sass/components/_jumbotron.scss */
      .jumbotron .slick-dots {
        margin-top: 2.5rem; } }
    /* line 103, src/Pandect.Web/Pandect.Web/Content/sass/components/_jumbotron.scss */
    .jumbotron .slick-dots li {
      width: 10px;
      height: 10px;
      margin: 0 5px 0 0; }
      @media (min-width: 992px) {
        /* line 103, src/Pandect.Web/Pandect.Web/Content/sass/components/_jumbotron.scss */
        .jumbotron .slick-dots li {
          width: 12px;
          height: 12px; } }
      /* line 113, src/Pandect.Web/Pandect.Web/Content/sass/components/_jumbotron.scss */
      .jumbotron .slick-dots li button {
        width: 10px;
        height: 10px;
        padding: 0;
        background-color: #bdbdbd;
        border-radius: 10px; }
        @media (min-width: 992px) {
          /* line 113, src/Pandect.Web/Pandect.Web/Content/sass/components/_jumbotron.scss */
          .jumbotron .slick-dots li button {
            width: 12px;
            height: 12px; } }
        /* line 125, src/Pandect.Web/Pandect.Web/Content/sass/components/_jumbotron.scss */
        .jumbotron .slick-dots li button::before {
          content: none; }
      /* line 130, src/Pandect.Web/Pandect.Web/Content/sass/components/_jumbotron.scss */
      .jumbotron .slick-dots li.slick-active button {
        background-color: #1f477a; }

@media (min-width: 768px) {
  /* line 1, src/Pandect.Web/Pandect.Web/Content/sass/components/_contact-panel.scss */
  .contact-panel {
    background-image: url("/Content/img/contact-background.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; }
    /* line 8, src/Pandect.Web/Pandect.Web/Content/sass/components/_contact-panel.scss */
    .contact-panel h2 a {
      font-size: 2.5rem;
      line-height: 3rem; } }

/* line 14, src/Pandect.Web/Pandect.Web/Content/sass/components/_contact-panel.scss */
.contact-panel p {
  font-family: "Ubuntu Light";
  font-size: 1.25rem;
  line-height: 1.75rem; }
  @media (min-width: 768px) {
    /* line 14, src/Pandect.Web/Pandect.Web/Content/sass/components/_contact-panel.scss */
    .contact-panel p {
      font-size: 1.5rem;
      line-height: 2rem; } }

/* line 25, src/Pandect.Web/Pandect.Web/Content/sass/components/_contact-panel.scss */
.contact-panel .contact-panel-icon::before {
  font-family: "Fontello";
  font-size: 3.5rem;
  line-height: 3.5rem;
  color: white;
  content: "\E804"; }
  @media (min-width: 768px) {
    /* line 25, src/Pandect.Web/Pandect.Web/Content/sass/components/_contact-panel.scss */
    .contact-panel .contact-panel-icon::before {
      font-size: 4.5rem;
      line-height: 4.5rem; } }

@media (min-width: 1200px) {
  /* line 39, src/Pandect.Web/Pandect.Web/Content/sass/components/_contact-panel.scss */
  .contact-panel .contact-panel-number {
    font-size: 2rem; } }

/* line 44, src/Pandect.Web/Pandect.Web/Content/sass/components/_contact-panel.scss */
.contact-panel a.link-border-arrow::after {
  bottom: 0.5rem; }
  @media (min-width: 768px) {
    /* line 44, src/Pandect.Web/Pandect.Web/Content/sass/components/_contact-panel.scss */
    .contact-panel a.link-border-arrow::after {
      bottom: -1px; } }

@media (max-width: 767.98px) {
  /* line 4, src/Pandect.Web/Pandect.Web/Content/sass/page/home.scss */
  .who-is-panel {
    background-image: none !important; } }

@media (min-width: 768px) {
  /* line 4, src/Pandect.Web/Pandect.Web/Content/sass/page/home.scss */
  .who-is-panel {
    background-position: top left;
    background-size: contain;
    background-repeat: no-repeat; } }

@media (min-width: 768px) {
  /* line 17, src/Pandect.Web/Pandect.Web/Content/sass/page/home.scss */
  .whoIsPandectCollapse.collapse {
    display: block; } }

/* line 22, src/Pandect.Web/Pandect.Web/Content/sass/page/home.scss */
.what-item {
  display: block;
  position: relative;
  z-index: 1;
  overflow: hidden; }
  /* line 28, src/Pandect.Web/Pandect.Web/Content/sass/page/home.scss */
  .what-item::before {
    display: block;
    padding-top: 100%;
    content: ""; }
    @media (min-width: 768px) and (max-width: 991.98px) {
      /* line 28, src/Pandect.Web/Pandect.Web/Content/sass/page/home.scss */
      .what-item::before {
        padding-top: 75%; } }
    @media (min-width: 992px) and (max-width: 1199.98px) {
      /* line 28, src/Pandect.Web/Pandect.Web/Content/sass/page/home.scss */
      .what-item::before {
        padding-top: 56%; } }
  /* line 42, src/Pandect.Web/Pandect.Web/Content/sass/page/home.scss */
  .what-item .what-item-content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3; }
  /* line 51, src/Pandect.Web/Pandect.Web/Content/sass/page/home.scss */
  .what-item img {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%); }
  /* line 58, src/Pandect.Web/Pandect.Web/Content/sass/page/home.scss */
  .what-item .what-item-title {
    display: inline-block;
    position: relative;
    max-width: 80%;
    padding: 0.5rem 1.5rem;
    background-color: #2b2b2b;
    opacity: 0.9;
    font-family: "Ubuntu Light";
    font-size: 1.5rem;
    line-height: 2rem;
    color: white; }
    @media (min-width: 992px) {
      /* line 58, src/Pandect.Web/Pandect.Web/Content/sass/page/home.scss */
      .what-item .what-item-title {
        font-size: 1.875rem;
        line-height: 2.25rem; } }
    @media (min-width: 1200px) {
      /* line 58, src/Pandect.Web/Pandect.Web/Content/sass/page/home.scss */
      .what-item .what-item-title {
        padding: 1rem 2rem; } }
  /* line 80, src/Pandect.Web/Pandect.Web/Content/sass/page/home.scss */
  .what-item .what-item-desc {
    display: none;
    position: relative;
    padding: 1rem 1.5rem;
    font-size: 1rem;
    color: white; }
  /* line 88, src/Pandect.Web/Pandect.Web/Content/sass/page/home.scss */
  .what-item .what-item-btn {
    display: none;
    position: relative;
    margin: 0 0 1.5rem 1.5rem;
    padding: 1rem 1.25rem;
    background-color: white;
    font-family: "Fontello";
    font-size: 1.75rem;
    line-height: 1.75rem;
    color: #11995e; }
  /* line 100, src/Pandect.Web/Pandect.Web/Content/sass/page/home.scss */
  .what-item:hover {
    text-decoration: none; }
    /* line 103, src/Pandect.Web/Pandect.Web/Content/sass/page/home.scss */
    .what-item:hover::after {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: black;
      opacity: 0.7;
      content: '';
      z-index: 2; }
    /* line 115, src/Pandect.Web/Pandect.Web/Content/sass/page/home.scss */
    .what-item:hover .what-item-title {
      background-color: #11995e;
      opacity: 1; }
    /* line 120, src/Pandect.Web/Pandect.Web/Content/sass/page/home.scss */
    .what-item:hover .what-item-desc {
      display: block; }
    /* line 124, src/Pandect.Web/Pandect.Web/Content/sass/page/home.scss */
    .what-item:hover .what-item-btn {
      display: inline-block; }

/* line 130, src/Pandect.Web/Pandect.Web/Content/sass/page/home.scss */
.why-item {
  position: relative; }
  /* line 133, src/Pandect.Web/Pandect.Web/Content/sass/page/home.scss */
  .why-item::before {
    position: absolute;
    top: -2.25rem;
    left: 1rem;
    font-family: "Fontello";
    font-size: 5rem;
    line-height: 5rem;
    width: 5rem;
    height: 5rem;
    color: #1f477a;
    content: "\E805";
    -webkit-transition: -webkit-transform .2s ease-out;
    transition: -webkit-transform .2s ease-out;
    transition: transform .2s ease-out;
    transition: transform .2s ease-out, -webkit-transform .2s ease-out; }
  /* line 147, src/Pandect.Web/Pandect.Web/Content/sass/page/home.scss */
  .why-item:hover::before {
    -webkit-transform: scale(1.1);
    transform: scale(1.1); }
  /* line 151, src/Pandect.Web/Pandect.Web/Content/sass/page/home.scss */
  .why-item h3 {
    font-size: 2.5rem;
    word-wrap: break-word; }

/* line 157, src/Pandect.Web/Pandect.Web/Content/sass/page/home.scss */
#homepageWhy .slick-dots {
  bottom: auto;
  margin-top: 0.5rem; }
  /* line 161, src/Pandect.Web/Pandect.Web/Content/sass/page/home.scss */
  #homepageWhy .slick-dots li {
    width: 10px;
    height: 10px;
    margin: 0 5px 0 0; }
    @media (min-width: 992px) {
      /* line 161, src/Pandect.Web/Pandect.Web/Content/sass/page/home.scss */
      #homepageWhy .slick-dots li {
        width: 12px;
        height: 12px; } }
    /* line 171, src/Pandect.Web/Pandect.Web/Content/sass/page/home.scss */
    #homepageWhy .slick-dots li button {
      width: 10px;
      height: 10px;
      padding: 0;
      background-color: #bdbdbd;
      border-radius: 10px; }
      @media (min-width: 992px) {
        /* line 171, src/Pandect.Web/Pandect.Web/Content/sass/page/home.scss */
        #homepageWhy .slick-dots li button {
          width: 12px;
          height: 12px; } }
      /* line 183, src/Pandect.Web/Pandect.Web/Content/sass/page/home.scss */
      #homepageWhy .slick-dots li button::before {
        content: none; }
    /* line 188, src/Pandect.Web/Pandect.Web/Content/sass/page/home.scss */
    #homepageWhy .slick-dots li.slick-active button {
      background-color: #1f477a; }
